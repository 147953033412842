import React from 'react';
import { shape, string } from 'prop-types';

import Breadcrumb from '../../../components-v2/sidebar/components/breadcrumb';
import { ViewOptions } from '../../sidebar/components/view-options/view-options.desktop';

const namespace = 'ui-search-skeleton';

const ContentCard = () => (
  <div className={`${namespace}__grid-item`}>
    <div className={`${namespace}__grid-image`} />
    <div className={`${namespace}__grid-info`}>
      <dl className={`${namespace}__text-mockup`}>
        <dt />
        <dd />
        <dd />
      </dl>
    </div>
  </div>
);

const SkeletonRescue = ({ breadcrumb, sort, title, label }) => (
  <div className={namespace}>
    <div className={`${namespace}__top-keywords`}>
      <p className="text-mock text-mock__breadcrumb" />
    </div>
    <div className={`${namespace}__mockup-main`}>
      <ViewOptions sort={sort} title={title} label={label} />
      <div className={`${namespace}__sidebar`}>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="ui-search-search-result">
          <p className="text-mock" />
        </div>
        <dl className={`${namespace}__filters-group`}>
          <dt />
          <dd />
          <dd />
          <dd />
          <dd />
        </dl>
      </div>
      <div className={`${namespace}__grid`}>
        {Array.from(Array(6), () => (
          <ContentCard />
        ))}
      </div>
    </div>
  </div>
);

SkeletonRescue.propTypes = {
  breadcrumb: shape({}),
  label: shape({}),
  sort: shape({}),
  title: string,
};

SkeletonRescue.defaultProps = {
  breadcrumb: null,
  label: null,
  sort: null,
  title: '',
};

const SkeletonRescueContainer = ({ view_options }) => <SkeletonRescue {...view_options} />;

SkeletonRescueContainer.propTypes = {
  view_options: shape({}),
};

SkeletonRescueContainer.defaultProps = {
  view_options: null,
};

export default SkeletonRescueContainer;
