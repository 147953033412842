import React from 'react';
import { element } from 'prop-types';

import Script from 'nordic/script';

const MainRescue = ({ children }) => (
  <>
    <Script priority={1} on="now">
      {
        // Registrar el Tag de la página
        'window.PAGE_TYPE = "rescue";'
      }
    </Script>
    {children}
  </>
);

MainRescue.propTypes = {
  children: element.isRequired,
};

export default MainRescue;
