import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIconImage } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--not-found';

const IconNotFound = (props) => (
  <div className={classnames(namespace, props.className)}>
    <BuildIconImage {...props} src="not-found.svg" description={props.description ?? 'image not found'} preload />
  </div>
);

IconNotFound.propTypes = {
  className: string,
  description: string,
};

export default IconNotFound;
