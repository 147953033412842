/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { string, shape, arrayOf, bool } from 'prop-types';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Labels from './components/labels';
import Actions from './components/actions';
import Modal from '../../components-v2/modal/modal-rescue';
import Skeleton from './components/skeleton';
import { trackSellerPixelsInfo } from '../../lib/tracking';
import IconNotFound from '../../components-v2/icons/sources/not-found';
import { TYPE_ISP, TYPE_ZRP, TYPE_MSHOPS_ZRP, TYPE_PI_ZRP, TYPE_ADULT } from './constants';

const namespace = 'ui-search-rescue';

const renderZrp = (message, labels, actions, type) => (
  <div className={classnames(namespace, `${namespace}--${type}`)}>
    <div className={`${namespace}__icon`}>
      <IconNotFound className={`${namespace}__icon`} />
    </div>
    <div className={`${namespace}__info`}>
      <h3 className={`${namespace}__title`}>{message.label.text}</h3>
      {!isEmpty(labels) && <Labels labels={labels} />}
      {actions && <Actions actions={actions} />}
    </div>
  </div>
);

const ContentModal = ({ message = null, labels = null, actions = null, type = '' }) => (
  <div className={classnames(namespace, `${namespace}--${type}`)}>
    <p
      dangerouslySetInnerHTML={{
        __html: `${message.label.text} ${labels[0].text}`,
      }}
    />
    <div className={`${namespace}__modal-actions`}>{actions && <Actions actions={actions} />}</div>
  </div>
);

ContentModal.propTypes = {
  actions: arrayOf(shape({})),
  labels: arrayOf(shape({})),
  message: shape({}),
  type: string,
};

const renderAdult = (rescue, breadcrumb, view_options) => (
  <>
    <Skeleton breadcrumb={breadcrumb} view_options={view_options} />
    <Modal modalTitle="Ver resultados para Adultos" modalUrl="" url="" label="" type="small" visible>
      <ContentModal actions={rescue.actions} message={rescue.message} labels={rescue.labels} type={rescue.type} />
    </Modal>
  </>
);

const RescuePage = ({ rescue, results, breadcrumb, view_options, shops }) => {
  useEffect(() => {
    if (shops && window.tracking_mediator) {
      trackSellerPixelsInfo(window.tracking_mediator, results);
    }
  }, [results, shops]);

  switch (rescue.type) {
    case TYPE_ISP:
    case TYPE_ZRP:
    case TYPE_PI_ZRP:
    case TYPE_MSHOPS_ZRP:
      return renderZrp(rescue.message, rescue.labels, rescue.actions, 'zrp');
    case TYPE_ADULT:
      return renderAdult(rescue, breadcrumb, view_options);

    default:
      return null;
  }
};

RescuePage.propTypes = {
  actions: arrayOf(
    shape({
      type: string,
      target: string,
      label: shape({
        text: string,
      }),
    }),
  ),
  breadcrumb: shape({}),
  rescue: shape({
    message: shape({
      label: shape({
        text: string,
      }),
    }).isRequired,
    labels: arrayOf(
      shape({
        text: string,
      }),
    ).isRequired,
    actions: string,
    type: string.isRequired,
  }),
  results: shape({}),
  shops: bool,
  view_options: shape({}),
};

export default RescuePage;
