const React = require('react');
const { shape, func } = require('prop-types');

const { Style } = require('nordic/style');

const Metadata = require('../../../../../components/metadata').default;
const Rescue = require('../../../../../components/rescue/rescue.desktop').default;
const { LayoutListener } = require('../../../../../components/context/layout');
const MainRescue = require('./main.rescue').default;
const history = require('../../../../../lib/history').default();

/**
 * Adults View
 */

const SearchRescue = ({ initialState = null }) => (
  <MainRescue>
    <div className="ui-search">
      <Metadata noIndex />
      <Style href="search.rescue.desktop.css" />
      <LayoutListener />
      <Rescue {...initialState} history={history} />
    </div>
  </MainRescue>
);

SearchRescue.propTypes = {
  currentUser: shape({}),
  initialState: shape({}),
  isCookieMarked: func,
};

module.exports = SearchRescue;
