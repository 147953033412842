import React from 'react';
import { string, shape, arrayOf } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-rescue';

/* eslint-disable react/no-array-index-key , react/no-danger */

const LabelsRescue = ({ labels }) => {
  const listLabels = labels.map((label, i) => (
    <li key={i} className={classnames(`${namespace}__item`)} dangerouslySetInnerHTML={{ __html: label.text }} />
  ));

  return <ul className={`${namespace}__list`}>{listLabels}</ul>;
};

/* eslint-enable */
LabelsRescue.propTypes = {
  labels: arrayOf(
    shape({
      text: string,
    }),
  ).isRequired,
};

export default LabelsRescue;
