import React, { useState } from 'react';
import { node, string } from 'prop-types';

import { Modal } from '@andes/modal';

import withComponentHandler from '../../hocs/with-component-handler';

const namespace = 'ui-search-rescue__modal';

/**
 * ModalRescue Component
 *
 * @typedef {Object} ModalRescueProps
 * @property {React.ReactNode} children - The content to be displayed inside the modal.
 * @property {string} modalTitle - The title of the modal.
 * @property {string} modalUrl - The URL associated with the modal.
 * @property {string} closeText - The text for the close button.
 * @property {string} type - The type of the modal, default is 'full'.
 * @property {boolean} visible - Determines if the modal is visible or not.
 *
 * @param {ModalRescueProps} props - The properties for the ModalRescue component.
 * @returns {JSX.Element} The rendered ModalRescue component.
 */

const ModalRescue = ({
  children = null,
  modalTitle = '',
  modalUrl = '',
  closeText = '',
  type = 'full',
  visible = false,
}) => {
  const [isOpen, setIsOpen] = useState(visible);

  const hideModal = () => {
    setIsOpen(false);
    window.history.back();
  };

  return (
    <Modal
      url={modalUrl}
      type={type}
      className={namespace}
      open={isOpen}
      onClose={hideModal}
      title={modalTitle}
      closeText={closeText}
      showCloseButton
    >
      {children}
    </Modal>
  );
};

Modal.propTypes = {
  children: node.isRequired,
  closeText: string,
  modalTitle: string,
  modalUrl: string,
  type: string,
  visible: Boolean,
};

ModalRescue.propTypes = {
  children: node.isRequired,
  closeText: string,
  modalTitle: string,
  modalUrl: string,
  type: string,
  visible: Boolean,
};

export default withComponentHandler(ModalRescue, { componentName: 'ModalRescue' });
